import * as React from "react";
import { Spinner } from "react-bootstrap";
const Pageloader = () => {
  return (

    <div className='surveypro-loader'>
      <center>
        <Spinner animation="border" as="span" variant="info" />
        <div className="h4">Loading...</div>
      </center>
    </div>
  );
};
export default Pageloader;          